import React from 'react';
import HorizontalTimeline from "react-horizontal-timeline";
import '../node_modules/leaflet/dist/leaflet.css';
import L from '../node_modules/leaflet/dist/leaflet.js';
import '../node_modules/leaflet.active-layers/src/ActiveLayers.js'
import fileLayer from '../node_modules/leaflet-filelayer/src/leaflet.filelayer.js';
import togeojson from '../node_modules/togeojson/togeojson.js';
import App from "./App";
import  './leaflet-plugins/layer/vector/KML.js'
import '../node_modules/font-awesome/css/font-awesome.css'
import '../node_modules/leaflet-groupedlayercontrol/src/leaflet.groupedlayercontrol.js'
import '../node_modules/leaflet.pattern'
import 'bootstrap/dist/css/bootstrap.css';

var fs2 = require('browserify-fs');
var curIdx = 0;
var dateItems = [];
var datas = [];
var selectedLayerObject;
var selectedLayerObjectColor;
export class Map extends React.Component {
    constructor() {
        super();
        this.state = {
            filterValue: 0,
            urunFilterValue: 0,
            centerValue: 0,
            filterBunyeValues: ["Killi", "Orta", "Kumlu", "Cakilli"],
            filterBunyeValuesText: ["Killi", "Orta", "Kumlu", "Çakıllı"],
            filterOneriValues: ["MAP yada TSP, AN, AS, CAN, KNO3 Ürününüze uygun uygulama için danışınız!", "DAP, AN, AS, CAN, KNO3 ve K2SO4 + (mikro element gübreleme ihtiyacı) Ürününüze uygun uygulama için danışınız!", "DAP, AN, AS, CAN, KNO3 ve K2SO4 Ürününüze uygun uygulama için danışınız!"],
            filterOneriValuesText: ["G1", "G2 + mikro element", "G2"],
            filterSFGvalues: ["F1", "F2", "F3", "F4", "F5", "DogalTarimDisi", "SuYuzeyi", "YolYerlesim"],
            filterSFGvaluesText: ["Çok Yüksek", "Yüksek", "Orta", "Düşük", "Çok Düşük", "Doğal Tarım Dışı", "Su Yüzeyi", "Yol Yerleşim"],
            filterUrunvalues: ["Ayçiçeği", "Arpa", "Buğday", "Haşhaş", "Mısır", "Şeker Pancarı", "Pamuk", "Patates", "Yonca", "Diğer"],
            filterUrunvaluesText: ["Ayçiçeği", "Arpa", "Buğday", "Haşhaş", "Mısır", "Şeker Pancarı", "Pamuk", "Patates", "Yonca", "Diğer"],
            filterBugdayvalues: ["İyi Buğday", "Zayıf Buğday"],
            filterBugdayvaluesText: ["İyi Buğday", "Zayıf Buğday"],
            filterHashasvalues: ["Haşhaş (Kışlık)", "Haşhaş (Yazlık)"],
            filterHashasvaluesText: ["Haşhaş (Kışlık)", "Haşhaş (Yazlık)"],
            selected: null,
            popupContent: ""
        };
        this.setPoint = this.setPoint.bind(this);
        this.sendLayerContent = this.sendLayerContent.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
        this.onMapDblClick = this.onMapDblClick.bind(this);
        this.handleCenter = this.handleCenter.bind(this);
        this.clickToFeature = this.clickToFeature.bind(this);
        this.getMapPolygons = this.getMapPolygons.bind(this);
        this.deleteMapPolygons = this.deleteMapPolygons.bind(this);
        this.getTextData();
    }
    setPoint(point1, point2, dblClckValue) {
        this.props.setPoints(point1, point2, dblClckValue);
    }
    sendLayerContent(layerContent) {
        this.props.sendLayerContent(layerContent);
    }
    onMapClick(e) {
        this.setPoint(e.latlng.lat, e.latlng.lng, 0);
    }
    onMapDblClick(e) {
        this.setPoint(e.latlng.lat, e.latlng.lng, 1);
    }
    componentWillMount() {
        this.getTextData();
    }
    componentWillUnmount() {
        this.getTextData();
    }
    readPlacemark(xmlData) {
        var Folder = xmlData.getElementsByTagName("Folder");
        var polyArray = [];
        var styleArray = [];
        var elementArray = [];
        for (var m = 0; m < Folder.length; m++) {
            var Placemark = Folder[m].getElementsByTagName("Placemark");
            var campus;
            var outerLine = 0.25;
            let mahalle_array = [];
            let mahalle_alan_valuesArray = [];
            let mahalle_mainIndex = 0;
            let alan_mainIndex = 0;
            let min_lat_coor = "";
            let max_lat_coor = "";
            let min_lng_coor = "";
            let max_lng_coor = "";
            let ilce_array = [];
            let ilce_alan_valuesArray = [];
            let ilce_mainIndex = 0;
            let mahalle_filter_array = [];
            let mahalle_filter_alan_valuesArray = [];
            let filter_mainIndex = 0;
            let firstValue = true;
            let Alan;
            for (var j = 0; j < Placemark.length; j++) {
                var MultiGeometry = Placemark[j].getElementsByTagName("Polygon");
                var outerBoundaryIs = MultiGeometry[0].getElementsByTagName("outerBoundaryIs");
                var outerBoundaryIsCoordinates = outerBoundaryIs[0].getElementsByTagName("coordinates")[0].firstChild.data;
                var outerCoordinates = outerBoundaryIsCoordinates.split(" ");
                var outerCoordinates_ = outerCoordinates;
                for (var k = 0; k < outerCoordinates.length; k++) {
                    outerCoordinates_[k] = outerCoordinates[k].split(",");
                }
                var innerBoundaryIs = MultiGeometry[0].getElementsByTagName("innerBoundaryIs");
                var innerCoordinates = "";
                try {
                    var innerBoundaryIsCoordinates = innerBoundaryIs[0].getElementsByTagName("coordinates")[0].firstChild.data;
                    innerCoordinates = innerBoundaryIsCoordinates.split(" ");
                    for (k = 0; k < innerCoordinates.length; k++) {
                        innerCoordinates[k] = innerCoordinates[k].split(",");
                    }
                }
                catch (err) {
                }
                var ExtendedData = Placemark[j].getElementsByTagName("ExtendedData");
                var SchemaData = ExtendedData[0].getElementsByTagName("SchemaData");
                var SimpleData = SchemaData[0].getElementsByTagName("SimpleData");
                try {
                    let filter_alan_valuesArray = [];
                    if (firstValue) {
                        for (k = 0; k < SimpleData.length; k++) {
                            if (SimpleData[k].attributes.name.nodeValue === "Mahalle") {
                                mahalle_mainIndex = k;
                                mahalle_array.push(SimpleData[mahalle_mainIndex].innerHTML);
                            }
                            else if (SimpleData[k].attributes.name.nodeValue === "İlçe") {
                                ilce_mainIndex = k;
                                ilce_array.push(SimpleData[ilce_mainIndex].innerHTML);
                            }
                            else if (SimpleData[k].attributes.name.nodeValue === "Alan") {
                                alan_mainIndex = k;
                                Alan = SimpleData[alan_mainIndex].innerHTML;
                                if (Alan.includes("m2")) {
                                    Alan = Alan.toString().substring(0, Alan.indexOf("m2") - 1).replace(",", "").replace(".", ",")
                                }

                                mahalle_alan_valuesArray.push(parseFloat(Alan.replace(',', '.')) / 1000);
                                ilce_alan_valuesArray.push(parseFloat(Alan.replace(',', '.')) / 1000);
                                filter_alan_valuesArray.push(parseFloat(Alan.replace(',', '.')) / 1000);

                                mahalle_filter_alan_valuesArray.push(filter_alan_valuesArray);
                            }
                            firstValue = false;
                        }
                    }
                    else {
                        Alan = SimpleData[alan_mainIndex].innerHTML;
                        if (Alan.includes("m2")) {
                            Alan = Alan.toString().substring(0, Alan.indexOf("m2") - 1).replace(",", "").replace(".", ",")
                        }
                        if (mahalle_array.indexOf(SimpleData[mahalle_mainIndex].innerHTML) < 0) {
                            mahalle_array.push(SimpleData[mahalle_mainIndex].innerHTML);
                            mahalle_alan_valuesArray.push(parseFloat(Alan.replace(',', '.')) / 1000);
                        }
                        else {
                            mahalle_alan_valuesArray[mahalle_array.indexOf(SimpleData[mahalle_mainIndex].innerHTML)]
                                += parseFloat(Alan.replace(',', '.')) / 1000;
                        }

                        if (ilce_array.indexOf(SimpleData[ilce_mainIndex].innerHTML) < 0) {
                            ilce_array.push(SimpleData[ilce_mainIndex].innerHTML);
                            ilce_alan_valuesArray.push(parseFloat(Alan.replace(',', '.')) / 1000);
                        }
                        else {
                            ilce_alan_valuesArray[ilce_array.indexOf(SimpleData[ilce_mainIndex].innerHTML)]
                                += parseFloat(Alan.replace(',', '.')) / 1000;
                        }

                        if (mahalle_filter_array.indexOf(SimpleData[mahalle_mainIndex].innerHTML + "_" + SimpleData[filter_mainIndex].innerHTML) < 0) {
                            if (SimpleData[filter_mainIndex].innerHTML !== "") {
                                mahalle_filter_array.push(SimpleData[mahalle_mainIndex].innerHTML + "_" + SimpleData[filter_mainIndex].innerHTML);
                                filter_alan_valuesArray.push(parseFloat(Alan.replace(',', '.')) / 1000);
                                mahalle_filter_alan_valuesArray.push(filter_alan_valuesArray);
                            }
                        }
                        else {
                            mahalle_filter_alan_valuesArray[mahalle_filter_array.indexOf(SimpleData[mahalle_mainIndex].innerHTML + "_" + SimpleData[filter_mainIndex].innerHTML)] =
                                parseFloat(mahalle_filter_alan_valuesArray[mahalle_filter_array.indexOf(SimpleData[mahalle_mainIndex].innerHTML + "_" + SimpleData[filter_mainIndex].innerHTML)])
                                + parseFloat(Alan.replace(',', '.') / 1000);
                        }
                    }
                }
                catch (err) {
                    console.log(err.message);
                }
            }
            for (j = 0; j < Placemark.length; j++) {
                MultiGeometry = Placemark[j].getElementsByTagName("Polygon");
                outerBoundaryIs = MultiGeometry[0].getElementsByTagName("outerBoundaryIs");
                outerBoundaryIsCoordinates = outerBoundaryIs[0].getElementsByTagName("coordinates")[0].firstChild.data;
                outerCoordinates = outerBoundaryIsCoordinates.split(" ");
                outerCoordinates_ = outerCoordinates;
                for (k = 0; k < outerCoordinates.length; k++) {
                    outerCoordinates_[k] = outerCoordinates[k].split(",");
                    if(k === 0)
                    {
                        min_lat_coor = outerCoordinates_[k][0];
                        max_lat_coor = outerCoordinates_[k][0];
                        min_lng_coor = outerCoordinates_[k][1];
                        max_lng_coor = outerCoordinates_[k][1];
                    }
                    if(min_lat_coor < outerCoordinates_[k][0])
                    {
                        min_lat_coor = outerCoordinates_[k][0]
                    }
                    if(max_lat_coor > outerCoordinates_[k][0])
                    {
                        max_lat_coor = outerCoordinates_[k][0]
                    }
                    if(min_lng_coor < outerCoordinates_[k][1])
                    {
                        min_lng_coor = outerCoordinates_[k][1]
                    }
                    if(max_lng_coor > outerCoordinates_[k][1])
                    {
                        max_lng_coor = outerCoordinates_[k][1]
                    }
                }
                innerBoundaryIs = MultiGeometry[0].getElementsByTagName("innerBoundaryIs");
                innerCoordinates = "";
                try {
                    innerBoundaryIsCoordinates = innerBoundaryIs[0].getElementsByTagName("coordinates")[0].firstChild.data;
                    innerCoordinates = innerBoundaryIsCoordinates.split(" ");
                    for (k = 0; k < innerCoordinates.length; k++) {
                        innerCoordinates[k] = innerCoordinates[k].split(",");
                    }
                }
                catch (err) {
                }
                ExtendedData = Placemark[j].getElementsByTagName("ExtendedData");
                SchemaData = ExtendedData[0].getElementsByTagName("SchemaData");
                SimpleData = SchemaData[0].getElementsByTagName("SimpleData");
                var popupContentText = "";
                var fillColorValue = "#00000000";
                var fillColorValue2 = "#000000";
                let ilAdi ="";
                let ilceAdi = "";
                let mahalleAdi = "";
                let FID = "";
                let parselNo = "";
                let AdaNo = "";
                try {
                    let simpleDataColumnsForFilter = 0;
                    let simpleDataColumnsForFilter2 = 0;
                    let Urun = "";
                    for (k = 0; k < SimpleData.length; k++) {
                        popupContentText += "<b>" + SimpleData[k].attributes.name.nodeValue + ": </b>";
                        if (SimpleData[k].attributes.name.nodeValue === "Alan" && SimpleData[k].innerHTML.includes("m2")) {
                            let Alan = SimpleData[k].innerHTML;
                            Alan = Alan.toString().substring(0, Alan.indexOf("m2") - 1).replace(",", "").replace(".", ",")
                            popupContentText += Alan + "<br/>";
                        }
                        else {
                            popupContentText += SimpleData[k].innerHTML + "<br/>";
                        }

                        if (SimpleData[k].attributes.name.nodeValue === "İl") {
                            ilAdi = SimpleData[k].innerHTML;
                        }
                        if (SimpleData[k].attributes.name.nodeValue === "İlçe") {
                            ilceAdi = SimpleData[k].innerHTML;
                        }
                        if (SimpleData[k].attributes.name.nodeValue === "Mahalle") {
                            mahalleAdi = SimpleData[k].innerHTML;
                        }
                        if (SimpleData[k].attributes.name.nodeValue === "FID") {
                            FID = SimpleData[k].innerHTML;
                        }
                        if (SimpleData[k].attributes.name.nodeValue === "ParselNo") {
                            parselNo = SimpleData[k].innerHTML;
                        }
                        if (SimpleData[k].attributes.name.nodeValue === "Ada") {
                            AdaNo = SimpleData[k].innerHTML;
                        }
                        if (SimpleData[k].attributes.name.nodeValue === "URUN") {
                            Urun = SimpleData[k].innerHTML;
                        }
                    }
                    popupContentText += "<b>mahalle_alan: </b>";
                    popupContentText += mahalle_alan_valuesArray[mahalle_array.indexOf(SimpleData[mahalle_mainIndex].innerHTML)] + "<br/>";
                    popupContentText += "<b>ilce_alan: </b>";
                    popupContentText += ilce_alan_valuesArray[ilce_array.indexOf(SimpleData[ilce_mainIndex].innerHTML)] + "<br/>";
                    for (var i = 0; i < mahalle_filter_array.length; i++) {
                        popupContentText += "<b>" + mahalle_filter_array[i] + ": </b>";
                        popupContentText += mahalle_filter_alan_valuesArray[i] + "<br/>";
                    }
                    popupContentText += "<b>" + "outerBoundaryIs" + ": </b>";
                    popupContentText += outerBoundaryIsCoordinates + "<br/>";
                    popupContentText += "<b>" + "innerBoundaryIs" + ": </b>";
                    popupContentText += innerBoundaryIsCoordinates + "<br/>";

                    let filterUrun = Urun;
                    switch (filterUrun) {
                        case "Hepsi": {
                            for (k = 0; k < this.state.filterUrunvalues.length; k++) {
                                if (SimpleData[simpleDataColumnsForFilter].innerHTML.includes(this.state.filterUrunvalues[k])) {
                                    fillColorValue = this.getColorURUN(k);
                                    break
                                }
                            }
                            for (k = 0; k < this.state.filterUrunvalues.length; k++) {
                                if (SimpleData[simpleDataColumnsForFilter2].innerHTML.includes(this.state.filterUrunvalues[k])) {
                                    fillColorValue2 = this.getColorURUN(k);
                                    break
                                }
                            }
                            break
                        }
                        case "Ayçiçeği": {
                            fillColorValue = this.getColorURUN(0);
                            break
                        }
                        case "Arpa": {
                            fillColorValue = this.getColorURUN(1);
                            break
                        }
                        case "Buğday": {
                            fillColorValue = this.getColorURUN(2);
                            break
                        }
                        case "Haşhaş": {
                            fillColorValue = this.getColorURUN(3);
                            break
                        }
                        case "Mısır": {
                            fillColorValue = this.getColorURUN(4);
                            break
                        }
                        case "Şeker Pancarı": {
                            fillColorValue = this.getColorURUN(5);
                            break
                        }
                        case "Pamuk": {
                            fillColorValue = this.getColorURUN(6);
                            break
                        }
                        case "Patates": {
                            fillColorValue = this.getColorURUN(7);
                            break
                        }
                        case "Yonca": {
                            fillColorValue = this.getColorURUN(8);
                            break
                        }
                        case "Diğer": {
                            fillColorValue = this.getColorURUN(9);
                            break
                        }
                        default:
                            break
                    }
                }
                catch (err) {
                    console.log(err.message);
                }

                let opacityValue_2 = .8
                let opacityValue_1 = .9;

                if (fillColorValue2 === "#000000")
                {
                    fillColorValue2 = fillColorValue;
                }
                var stripes = new L.StripePattern({
                    angle: 45,
                    color: fillColorValue,
                    spaceColor: fillColorValue2,
                    opacity: 1,
                    spaceOpacity: 1
                }).addTo(App.map);

                if (fillColorValue === fillColorValue2)
                {
                    campus = {
                        "type": "Feature",
                        "properties": {
                            "popupContent":
                            popupContentText,
                            "style": {
                                weight: outerLine,
                                color: "#1b1a1a",
                                opacity: opacityValue_1,
                                fillColor: fillColorValue,
                                fillOpacity: opacityValue_2
                            },
                            "NAME": (this.changeText(ilAdi) + "-" + this.changeText(ilceAdi) + "-"
                                + this.changeText(mahalleAdi) + "-" + AdaNo + "-" + parselNo
                                + "," + min_lat_coor + "," + max_lat_coor + "," + min_lng_coor + "," + max_lng_coor
                            )
                        },
                        "geometry": {
                            "type": "MultiPolygon",
                            "coordinates": [
                                [outerCoordinates_, innerCoordinates]
                            ]
                        }
                    };
                }
                else {
                    campus = {
                        "type": "Feature",
                        "properties": {
                            "popupContent":
                            popupContentText,
                            "style": {
                                weight: outerLine,
                                color: "#1b1a1a",
                                opacity: opacityValue_1,
                                fillPattern: stripes,
                                fillOpacity: opacityValue_2
                            },
                            "NAME": (this.changeText(ilAdi) + "-" + this.changeText(ilceAdi) + "-"
                                + this.changeText(mahalleAdi) + "-" + AdaNo + "-" + parselNo
                                + "," + min_lat_coor + "," + max_lat_coor + "," + min_lng_coor + "," + max_lng_coor
                            )
                        },
                        "geometry": {
                            "type": "MultiPolygon",
                            "coordinates": [
                                [outerCoordinates_, innerCoordinates]
                            ]
                        }
                    };
                }
                try{

                    polyArray.push(campus);
                    styleArray.push({
                        style: function (feature) {
                            return feature.properties && feature.properties.style;
                        }, onEachFeature: this.onEachFeature.bind(this)

                    });
                }
                catch (err) {
                    console.log(err.message);
                }
            }
        }
        elementArray.push(polyArray, styleArray);
        App.elementArray = elementArray;

    }
    changeText(text){
        return text.toUpperCase().replace(/İ/g, 'I').replace(/Ö/g, 'O').replace(/Ü/g, 'U').replace(/Ç/g, 'C').replace(/Ğ/g, 'G').replace(/Ş/g, 'S');
    }
    getAllXml(){
        this.createMap(App.map.getCenter().lat, App.map.getCenter().lng, App.map.getZoom());
        this.getXmlDatas("map.kml");
    }
    getXmlDatas(kmlPath) {
        this.xml = new XMLHttpRequest();
        this.xml.open('GET', kmlPath, false);
        this.xml.send();
        let xmlData = this.xml.responseXML;
        if (!xmlData) {
            xmlData = (new DOMParser()).parseFromString(this.xml.responseText, 'text/xml');
        }
        this.readPlacemark(xmlData);
    }
    onEachFeature(feature, layer) {
        layer.on({
            click: this.clickToFeature,
        });
    }
    clickToFeature(e) {
        let previous;
        if (this.state.selected !== null) {
            previous = this.state.selected;
        }
        if (previous) {
            if (this.state.selected === null || this.state.selected._leaflet_id !== e.target._leaflet_id) {
                previous.setStyle({
                    weight: .25,
                    dashArray: '',
                    color: "#1b1a1a",
                    opacity: .9,
                });
            }
        }
        e.target.setStyle({
            weight: 5,
            dashArray: '',
            color: "#fff"
        });


        this.setState({popupContent: e.target.feature.properties.popupContent});
        this.sendLayerContent(e.target.feature.properties.popupContent);

        this.setState({selected: e.target});

        if (!L.Browser.ie && !L.Browser.opera) {
            e.target.bringToFront();
        }

        document.getElementById("saveButton").className = "btn btn-outline-success btn-sm btnSave";
        let statementSelectionsElements = document.getElementsByClassName("statementSelections");
        for (var i = 0; i < statementSelectionsElements.length; i++) {
            statementSelectionsElements[i].style.display = "table-row";
        }

        selectedLayerObject = e;
        selectedLayerObjectColor = e.target.feature.properties.style.fillColor;
    }
    createMap(_lan, _lot, _zoom) {
        try {
            if (App.map != null) {
                App.map.remove();
                App.map = null;
            }
            App.map = L.map('map', {center: [_lan, _lot], zoom: _zoom});
            App.map.attributionControl.setPrefix('');
            fileLayer(null, L, togeojson)
            L.tileLayer('http://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {maxZoom: 20,subdomains: ['mt0', 'mt1', 'mt2', 'mt3']}).addTo(App.map);
            this.createCenterFilter();
            this.createColorLegend();
            //this.createKmlUpload();
            //this.createWatermark();
        }
        catch (err) {
            console.log(err.message);
        }
    }
    createCenterFilter() {
        var legend = L.control({position: 'topright'});
        legend.onAdd = function (map) {
            var div = L.DomUtil.create('div', 'info legend');
            div.innerHTML = '<i class="fa fa-filter selectIcon .droplist"></i><select id=\'centerList\' class="form-control form-control-sm droplist">' +
                '<option value="" selected disabled>Merkez Seçimi:</option>'+
                '<option value="38.333800, 30.182391,15">Afyon</option>' +
                '<option value="37.574628,27.327764,14">Aydın</option>' +
                '<option value="37.968193,32.906950,14">Konya</option>' +
                '</select>';
            return div;
        };
        legend.addTo(App.map);
        document.getElementById("centerList").selectedIndex = this.state.centerValue;
        document.getElementById("centerList").addEventListener("change", this.handleCenter, false);
    }
    createColorLegend() {
        var legend = L.control({position: 'bottomright', paddingBottom: '150px'});
        let filterValuesArray;
        let colorArray = [];
        switch (this.state.filterValue) {
            case 0: {
                switch (this.state.urunFilterValue) {
                    case 3: {
                        filterValuesArray = this.state.filterBugdayvaluesText;
                        for (var i = 0; i < filterValuesArray.length; i++) {
                            colorArray.push(this.getColorBugday(i));
                        }
                        break
                    }
                    case 4: {
                        filterValuesArray = this.state.filterHashasvaluesText;
                        for (i = 0; i < filterValuesArray.length; i++) {
                            colorArray.push(this.getColorHashas(i));
                        }
                        break
                    }
                    default : {
                        filterValuesArray = this.state.filterUrunvaluesText;
                        for (i = 0; i < filterValuesArray.length; i++) {
                            colorArray.push(this.getColorURUN(i));
                        }
                        break
                    }
                }
                break
            }
            default:
            {
                break
            }
        }
        legend.onAdd = function (map) {
            var div = L.DomUtil.create('div', 'info2 legend2')
            for (i = 0; i < filterValuesArray.length; i++) {
                div.innerHTML +=
                    '<i style="background:' + colorArray[i] + '"></i> ' +
                    filterValuesArray[i] + '<br><div style="height: 5px"/>';
            }
            return div;
        };
        legend.addTo(App.map);
    }
    createWatermark() {
        L.Control.Watermark = L.Control.extend({
            onAdd: function (map) {
                var img = L.DomUtil.create('img');
                img.src = './logo.png';
                img.style.height = '40px';
                return img;
            }
        });

        L.control.watermark = function (opts) {
            return new L.Control.Watermark(opts);
        }

        L.control.watermark({position: 'bottomleft'}).addTo(App.map);
    }
    getColorURUN(d) {
        return d > 8 ? '#676765' :
            d > 7 ? '#25c97a' :
                d > 6 ? '#c9222a' :
                    d > 5 ? '#dee3dd' :
                        d > 4 ? '#f36523' :
                            d > 3 ? '#343f96' :
                                d > 2 ? '#96247b' :
                                    d > 1 ? '#46acb1' :
                                        d > 0 ? '#276c4f' :
                                            '#ffcc33';
    }
    getColorBugday(d) {
        return d > 0 ? '#90cdd0' :
            '#2a676a';
    }
    getColorHashas(d) {
        return d > 0 ? '#c07baf' :
            '#5a1549';
    }
    handleCenter() {
        var e = document.getElementById("centerList");
        if (e.options[e.selectedIndex].value !== "") {
            var centerObj = e.options[e.selectedIndex].value.toString().split(",");
            App.map.flyTo([parseFloat(centerObj[0]), parseFloat(centerObj[1])], parseFloat(centerObj[2]));
        }
    }
    authenticate(){
        return new Promise(resolve => setTimeout(resolve, 2000))
    }
    getTextData(){
        fs2.readFile('/home/beyan.txt', 'utf-8', function (err, data) {
            if (err) {
                throw err;
            }
            datas = data.split("\n");
        });
    }
    getMapPolygons(){
        this.deleteMapPolygons();
        let layerArray_ = [];
        for(var i = 0; i < App.elementArray[0].length; i++)
        {
            let layer = L.geoJson(App.elementArray[0][i],App.elementArray[1][i]);
            layerArray_.push(layer);
            layer.addTo(App.map);
        }
        App.layerArray = layerArray_;
    }
    deleteMapPolygons() {
        try {
            for (var i = 0; i < App.layerArray.length; i++) {
                App.map.removeLayer(App.layerArray[i]);
            }
        }
        catch (e) {

        }
    }
    componentDidMount(){
        this.createMap(38.333800, 30.182391,15);
        this.getAllXml();
        this.getTextData();
        this.getMapPolygons();
        this.authenticate().then(() => {
            const ele = document.getElementById('ipl-progress-indicator')
            if(ele){
                ele.classList.add('available')
                setTimeout(() => {
                    ele.outerHTML = ''
                }, 2000)
            }
        })
    }
    render() {
        var xml;
        return (
            <div className="full-height App">
                    <div id={"HTimeline"} style={{width: "100%",height: "50px",fontSize: "15px"}}>
                        <HorizontalTimeline
                            styles={{
                                background: "#f8f8f8",
                                foreground: "#1A79AD",
                                outline: "#dfdfdf"
                            }}
                            values={dateItems.map(x => x.data)}
                            index={curIdx}
                            indexClick={(index) => {
                                curIdx = index
                                this.setState({prevIdx: curIdx});
                                this.handleLayerFilter();
                            }}
                            getLabel={function(date) { return date}}
                        />
                    </div>
                <div id="map" className="full-height map">
                </div>
            </div>
        )
    }

}
export default Map;