import React from 'react';
import App from "./App";
import Map from "./Map";
import '../node_modules/leaflet-groupedlayercontrol/src/leaflet.groupedlayercontrol.js'
import '../node_modules/leaflet.pattern'
import L from "leaflet/dist/leaflet";

var fs = require('browserify-fs');
const FilterRow = (props) => {
    return (
        <tr>
            <td>
                { props.data.name }
            </td>
            <td>
                { props.data.value }
            </td>
            <td>
                { props.data.percent }
            </td>
        </tr>
    );
}

var PieChart = require("react-chartjs").Pie;
var chartData = [
    {
        value: 0,
        color:"#ffcc33",
        highlight: "#ffd65b",
        label: "Ayçiçeği"
    },
    {
        value: 0,
        color: "#276c4f",
        highlight: "#528972",
        label: "Arpa"
    },
    {
        value: 0,
        color: "#46acb1",
        highlight: "#6abcc0",
        label: "Buğday"
    },
    {
        value: 0,
        color: "#96247b",
        highlight: "#ab4f95",
        label: "Haşhaş"
    },
    {
        value: 0,
        color: "#343f96",
        highlight: "#5c65ab",
        label: "Mısır"
    },
    {
        value: 0,
        color: "#f36523",
        highlight: "#f5834e",
        label: "Şeker Pancarı"
    },
    {
        value: 0,
        color: "#dee3dd",
        highlight: "#e4e8e3",
        label: "Pamuk"
    },
    {
        value: 0,
        color: "#c9222a",
        highlight: "#d34e54",
        label: "Patates"
    },
    {
        value: 0,
        color: "#25c97a",
        highlight: "#50d394",
        label: "Yonca"
    },
    {
        value: 0,
        color: "#676765",
        highlight: "#858583",
        label: "Diğer"
    }
];

var selected = null;

var layout = (<div></div>);
var outerCoordinates_ = [];
var innerCoordinates_ = [];
export class Widget extends React.Component {
    constructor(){
        super();
        this.state = {Bunye: "", GubOner: "", SFG: "", SFGtext: "", Alan: "",
            mahalle_alan: "", ilce_alan: "", mahalle_adi: "", ilce_adi: "", il_adi: "", FID:"", filter: "",
            btn_disabled: false,
            URUN :"-", EKIM_TARIH: "-", HASAT_TARIH: "-",URUN2 :"-", EKIM_TARIH2: "-", HASAT_TARIH2: "-",
            Beyan: "",
            selected: null,
            LayerName: "",
            filterBunyeValues:["Killi","Orta", "Kumlu", "Cakilli"],
            filterBunyeValuesText:["Killi","Orta", "Kumlu", "Çakıllı"],
            filterOneriValues:["MAP yada TSP, AN, AS, CAN, KNO3 Ürününüze uygun uygulama için danışınız!","DAP, AN, AS, CAN, KNO3 ve K2SO4 + (mikro element gübreleme ihtiyacı) Ürününüze uygun uygulama için danışınız!", "DAP, AN, AS, CAN, KNO3 ve K2SO4 Ürününüze uygun uygulama için danışınız!"],
            filterOneriValuesText:["G1", "G2 + mikro element", "G2"],
            filterSFGvalues:["F1","F2","F3","F4","F5","DogalTarimDisi","SuYuzeyi","YolYerlesim"],
            filterSFGvaluesText: ["Çok Yüksek", "Yüksek", "Orta", "Düşük", "Çok Düşük", "Doğal Tarım Dışı", "Su Yüzeyi", "Yol Yerleşim"],
            data: []}
        this.baseState = this.state;
        this.sendLayerContent = this.sendLayerContent.bind(this);
        this.saveStatementData = this.saveStatementData.bind(this);

        this.writeStatementData = this.writeStatementData.bind(this);
    }

    sendLayerContent(layerContent) {
        this.props.sendLayerContent(layerContent);
    }
    componentDidUpdate ()
    {
        if (this.prewLayerContentObj !== this.props.layerContentObj) {
            this.setState(this.baseState);
            let layerContentArray_ = (this.props.layerContentObj.split('<br/>'));
            this.prewLayerContentObj = this.props.layerContentObj;
            var filterName = "";
            var ilAdi = "";
            var ilceAdi = "";
            var mahalleAdi = "";
            var FID = "";
            var mahalleAlan = "";
            let data_ = [];
            for (var i = 0; i < layerContentArray_.length; i++) {
                var attributeNamesStr = layerContentArray_[i].toString().substr(3, layerContentArray_[i].indexOf("</b>") - 5);
                let percentValue = ((parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                    layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1))) * 100) / parseFloat(mahalleAlan)).toString().replace(".",",")
                if (attributeNamesStr !== "") {
                    switch (attributeNamesStr) {
                        case ("ID"): {
                            try {
                                let Id =  layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)

                                fs.exists('/home/' + Id + '.txt',function(exists){
                                    if (exists) {
                                        fs.readFile('/home/' + Id + '.txt', 'utf-8', function (err, data) {
                                            if (err) {
                                                throw err;
                                            }
                                            this.setState({BeyannemeDegerleri: data});
                                        });
                                    }
                                });
                            }
                            catch (err) {
                                console.log(err.message);
                            }
                            break
                        }
                        case ("outerBoundaryIs"): {
                            let outerCoordinates = layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length).split(" ");
                            outerCoordinates_ = outerCoordinates;
                            for (var k = 0; k < outerCoordinates.length; k++) {
                                outerCoordinates_[k] = outerCoordinates[k].split(",");
                            }
                            break
                        }
                        case ("innerBoundaryIs"): {
                            let innerCoordinates = layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length).split(" ");
                            innerCoordinates_ = innerCoordinates;
                            for (var k = 0; k < innerCoordinates.length; k++) {
                                innerCoordinates_[k] = innerCoordinates[k].split(",");
                            }
                            break
                        }
                        case ("LayerName"): {
                            this.setState({LayerName: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("Bunye"): {
                            this.setState({Bunye: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("GubOner"): {
                            this.setState({GubOner: this.state.filterOneriValuesText[this.state.filterOneriValues.indexOf(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length))]})
                            break
                        }
                        case ("SFG"): {
                            this.setState({SFG: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            this.setState({SFGtext: this.state.filterSFGvaluesText[this.state.filterSFGvalues.indexOf(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length))]})
                            break
                        }
                        case ("Alan"): {
                            this.setState({
                                Alan: (parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                    layerContentArray_[i].indexOf(",") - (layerContentArray_[i].indexOf("</b>") + 1))) /1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da"
                            })
                            break
                        }
                        case ("mahalle_alan"): {
                            mahalleAlan = layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1));
                            this.setState({
                                mahalle_alan: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                    layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da"
                            });
                            break
                        }
                        case ("Mahalle"): {
                            mahalleAdi = layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length);
                            this.setState({mahalle_adi: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("ilce_alan"): {
                            this.setState({
                                ilce_alan: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                    layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da"
                            });
                            break
                        }
                        case ("İlçe"): {
                            this.setState({ilce_adi: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            ilceAdi = layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length);
                            break
                        }
                        case ("İl"): {
                            this.setState({il_adi: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            ilAdi = layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length);
                            break
                        }
                        case ("FID"): {
                            this.setState({FID: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            FID = layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length);
                            break
                        }
                        case ("filter"): {
                            switch (layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)) {
                                case("Bunye"): {
                                    this.setState({filter: "Bünye"});
                                    filterName = "Bünye";
                                    break
                                }
                                case("GubOner"): {
                                    this.setState({filter: "Öneri"});
                                    filterName = "Öneri";
                                    break
                                }
                                case("SFG"): {
                                    this.setState({filter: "Verim"});
                                    filterName = "SFG";
                                    break
                                }
                                default:
                                {
                                    break
                                }
                            }
                            break
                        }
                        case ("URUN"): {
                            this.setState({URUN: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("HASAT_TARI"): {
                            this.setState({HASAT_TARIH: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("EKIM_1"): {
                            this.setState({EKIM_TARIH: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("HASAT_1"): {
                            this.setState({HASAT_TARIH: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("URUN_2"): {
                            this.setState({URUN2: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("EKIM_2"): {
                            this.setState({EKIM_TARIH2: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("HASAT_2"): {
                            this.setState({HASAT_TARIH2: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        default: {
                            switch (filterName) {
                                case("Bünye"): {
                                    if (attributeNamesStr.toString().substr(0, attributeNamesStr.indexOf("_")) === mahalleAdi) {
                                        data_.push({
                                            name: this.state.filterBunyeValuesText[this.state.filterBunyeValues.indexOf(attributeNamesStr.toString().substr(attributeNamesStr.indexOf("_") + 1,
                                                attributeNamesStr.length - (attributeNamesStr.indexOf("_") + 1)))],
                                            value: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da",
                                            percent: "%" + percentValue.substr(0, percentValue.indexOf(",") + 2) ,
                                            valueNum: parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",","))});
                                    }
                                    break
                                }
                                case("Öneri"): {
                                    if (attributeNamesStr.toString().substr(0, attributeNamesStr.indexOf("_")) === mahalleAdi) {
                                        data_.push({
                                            name: this.state.filterOneriValuesText[this.state.filterOneriValues.indexOf(attributeNamesStr.toString().substr(attributeNamesStr.indexOf("_") + 1,
                                                attributeNamesStr.length - (attributeNamesStr.indexOf("_") + 1)))],
                                            value: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da",
                                            percent: "%" + percentValue.substr(0, percentValue.indexOf(",") + 2),
                                            valueNum: parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",","))});
                                    }
                                    break
                                }
                                case("SFG"): {
                                    if (attributeNamesStr.toString().substr(0, attributeNamesStr.indexOf("_")) === mahalleAdi) {
                                        data_.push({
                                            name: this.state.filterSFGvaluesText[this.state.filterSFGvalues.indexOf(attributeNamesStr.toString().substr(attributeNamesStr.indexOf("_") + 1,
                                                attributeNamesStr.length - (attributeNamesStr.indexOf("_") + 1)))],
                                            value: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da",
                                            percent: "%" + percentValue.substr(0, percentValue.indexOf(",") + 2),
                                            valueNum: parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",","))});
                                    }
                                    break
                                }
                                default:
                                {
                                    break
                                }
                            }
                            break
                        }
                    }
                }
            }
            this.getGraphicMahalle(ilAdi, ilceAdi, mahalleAdi);
            data_.sort(function (a, b) {
                return a.valueNum - b.valueNum;
            });
            let _data_ = []
            for (i = data_.length - 1; i >= 0 ; i--)
            {
                _data_.push(data_[i]);
            }
            this.setState({data: _data_});


            this.getTextData(ilAdi, ilceAdi, mahalleAdi, FID);
        }
    }

    getGraphicMahalle(il, ilce, mahalle) {
        let datas = [];
        var Urunvalues = ["Ayçiçeği", "Arpa", "Buğday", "Haşhaş", "Mısır", "Şeker Pancarı", "Pamuk", "Patates", "Yonca", "Diğer"];

        fs.exists('/home/beyan.txt',function(exists){
            if (exists) {
                fs.readFile('/home/beyan.txt', 'utf-8', function (err, data) {
                    if (!err) {

                        datas = data.split("\n");

                        for (var i = 0; i < chartData.length; i++) {
                            chartData[i].value = 0;
                        }
                        let urunler = chartData;
                        for (i = 0; i < datas.length; i++) {
                            if (datas[i].includes(il + " " + ilce + " " + mahalle)) {
                                let beyan = datas[i].split(": ")[1];
                                if (Urunvalues.indexOf(beyan) > 0) {
                                    urunler[Urunvalues.indexOf(beyan)].value++;
                                }
                            }
                        }
                        let total = 0;
                        for (i = 0; i < chartData.length; i++) {
                            total += urunler[i].value;
                        }
                        for (i = 0; i < chartData.length; i++) {
                            chartData[i].value = ((urunler[i].value * 100) / total).toFixed(2);
                        }
                    }
                });
            }
        });
    }

    getTextData(il, ilce, mahalle, FID)
    {
        let datas = [];
        var Urunvalues = ["Ayçiçeği", "Arpa", "Buğday", "Haşhaş", "Mısır", "Şeker Pancarı", "Pamuk", "Patates", "Yonca", "Diğer"];
        fs.exists('/home/beyan.txt',function(exists){
            if (exists) {
                fs.readFile('/home/beyan.txt', 'utf-8', function (err, data) {
                    if (err) {
                        throw err;
                    }
                    datas = data.split("\n");
                    for(var i = 0; i < datas.length; i++)
                    {
                        if (datas[i].includes(il + " " + ilce + " " + mahalle + " " + FID)) {
                            let beyan = datas[i].split(": ")[1];
                            if(beyan.includes(" ") && beyan !== "Şeker Pancarı")
                            {
                                beyan = beyan.split(" ")[1]
                            }
                            switch (Urunvalues.indexOf(beyan))
                            {
                                case(0):
                                {
                                    document.getElementById("radio1").click();
                                    break;
                                }
                                case(1):
                                {
                                    document.getElementById("radio2").click();
                                    break;
                                }
                                case(2):
                                {
                                    document.getElementById("radio3").click();
                                    break;
                                }
                                case(3):
                                {
                                    document.getElementById("radio4").click();
                                    break;
                                }
                                case(4):
                                {
                                    document.getElementById("radio5").click();
                                    break;
                                }
                                case(5):
                                {
                                    document.getElementById("radio6").click();
                                    break;
                                }
                                case(6):
                                {
                                    document.getElementById("radio7").click();
                                    break;
                                }
                                case(7):
                                {
                                    document.getElementById("radio8").click();
                                    break;
                                }
                                case(8):
                                {
                                    document.getElementById("radio9").click();
                                    break;
                                }
                                case(9):
                                {
                                    document.getElementById("radio10").click();
                                    break;
                                }
                                default: {
                                    break;
                                }
                            }
                        }
                    }

                });
            }
        });
    }

    writeStatementData(il, ilce, mahalle, FID, beyan)
    {
        let datas = [];
        fs.readFile('/home/beyan.txt', 'utf-8', function (err, data) {
            if (err) {
                throw err;
            }
            datas = data.split("\n");
            let includes = false;
            for(var i = 0; i < datas.length; i++)
            {
                if (datas[i].includes(il + " " + ilce + " " + mahalle + " " + FID)) {
                    datas[i] = (il + " " + ilce + " " + mahalle + " " + FID + ": " + beyan);
                    includes = true;
                    break;
                }
            }
            if (!includes)
            {
                datas.push(il + " " + ilce + " " + mahalle + " " + FID + ": " + beyan);
            }
            let dataText = "";
            for(i = 0; i < datas.length; i++)
            {
                dataText = dataText + datas[i] + "\n";
            }
            if (dataText !== "") {
                fs.writeFile('/home/beyan.txt', dataText, function (err) {
                    if (err) throw err;
                    alert("Beyan Kaydedildi.");
                });
            }
        });
    }

    saveStatementData()
    {
        var Urunvalues = ["Ayçiçeği", "Arpa", "Buğday", "Haşhaş", "Mısır", "Şeker Pancarı", "Pamuk", "Patates", "Yonca", "Diğer"];
        if(this.state.Beyan !== "") {
            this.writeStatementData(this.state.il_adi, this.state.ilce_adi, this.state.mahalle_adi, this.state.FID, this.state.Beyan);
        }
        var campus = {
            "type": "Feature",
            "properties": {
                "popupContent":
                this.props.layerContentObj,
                "style": {
                    weight: 0.25,
                    color: "#1b1a1a",
                    opacity: .9,
                    fillColor: this.getColorURUN(Urunvalues.indexOf(this.state.Beyan)),
                    fillOpacity: .8
                },
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [outerCoordinates_, innerCoordinates_]
                ]
            }}




        for(var i = 0; i < App.elementArray[0].length; i++) {
            if (App.elementArray[0][i].geometry.coordinates[0][0][0][0] === campus.geometry.coordinates[0][0][0][0]) {
                App.elementArray[0][i] = campus;
            }
        }

        /*App.elementArray[0].forEach((elementArrayPoly) => {
          if(elementArrayPoly.geometry.coordinates[0][0][0][0] === campus.geometry.coordinates[0][0][0][0])
          {
              elementArrayPoly = campus;
          }
        })*/

        new Map().getMapPolygons();
        /*App.layerMap.geoJSON([campus], {
            style: function (feature) {
                return feature.properties && feature.properties.style;
            }, onEachFeature: this.onEachFeature.bind(this)

        }).addTo(App.map);*/
    }

    onEachFeature(feature, layer) {
        layer.on({

            click: this.clickToFeature.bind(this),
        });
    }

    clickToFeature(e) {
        let previous;
        if (selected !== null) {
            previous = selected;
        }
        if (previous) {
            if (selected === null || selected._leaflet_id !== e.target._leaflet_id) {
                previous.setStyle({
                    weight: .25,
                    dashArray: '',
                    color: "#1b1a1a",
                    opacity: .9,
                });
            }
        }
        e.target.setStyle({
            weight: 5,
            dashArray: '',
            color: "#fff"
        });

        selected = e.target;
        this.sendLayerContent(e.target.feature.properties.popupContent);

        /*if (!App.layerMap.Browser.ie && !App.layerMap.Browser.opera) {
            e.target.bringToFront();
        }*/
    }

    getColorURUN(d) {
        return d > 8 ? '#676765' :
            d > 7 ? '#25c97a' :
                d > 6 ? '#c9222a' :
                    d > 5 ? '#dee3dd' :
                        d > 4 ? '#f36523' :
                            d > 3 ? '#343f96' :
                                d > 2 ? '#96247b' :
                                    d > 1 ? '#46acb1' :
                                        d > 0 ? '#276c4f' :
                                            '#ffcc33';
    }

    handleChange(event){
        this.setState({Beyan: event.target.value});

    }

    render() {
        var prewLayerContentObj;
        let counter = 0;
        var rows = this.state.data.map(filtre => {
            return <FilterRow
                key = {
                    counter++
                }
                data = {
                    filtre
                }
            />
        })

        fs.exists('/home/beyan.txt',function(exists){
            if (!exists) {
                fs.mkdir('/home', function() {});
                fs.writeFile('/home/beyan.txt', 'Aydın Söke Sarıkemer 0: Pamuk\n' +
                    'Aydın Söke Sarıkemer 1: Buğday\n' +
                    'Aydın Söke Sarıkemer 2: Buğday\n' +
                    'Aydın Söke Sarıkemer 3: Buğday\n' +
                    'Aydın Söke Sarıkemer 4: Buğday\n' +
                    'Aydın Söke Sarıkemer 5: Buğday\n' +
                    'Aydın Söke Sarıkemer 6: Buğday\n' +
                    'Aydın Söke Sarıkemer 7: Pamuk\n' +
                    'Aydın Söke Sarıkemer 8: Pamuk\n' +
                    'Aydın Söke Sarıkemer 9: Buğday\n' +
                    'Aydın Söke Sarıkemer 11: Pamuk\n' +
                    'Aydın Söke Sarıkemer 12: Buğday\n' +
                    'Aydın Söke Sarıkemer 13: Buğday\n' +
                    'Aydın Söke Sarıkemer 14: Buğday\n' +
                    'Afyon Sandıklı Alamescit 297: Patates\n' +
                    'Aydın Söke Sarıkemer 16: Pamuk\n' +
                    'Aydın Söke Sarıkemer 17: Buğday\n' +
                    'Aydın Söke Sarıkemer 18: Buğday\n' +
                    'Aydın Söke Sarıkemer 19: Buğday\n' +
                    'Aydın Söke Sarıkemer 21: Buğday\n' +
                    'Aydın Söke Sarıkemer 22: Buğday\n' +
                    'Aydın Söke Sarıkemer 23: Pamuk\n' +
                    'Aydın Söke Sarıkemer 24: Pamuk\n' +
                    'Aydın Söke Sarıkemer 25: Buğday\n' +
                    'Aydın Söke Sarıkemer 26: Buğday\n' +
                    'Aydın Söke Sarıkemer 27: Pamuk\n' +
                    'Aydın Söke Sarıkemer 28: Buğday\n' +
                    'Aydın Söke Sarıkemer 29: Pamuk\n' +
                    'Aydın Söke Sarıkemer 30: Pamuk\n' +
                    'Aydın Söke Sarıkemer 41: Buğday\n' +
                    'Aydın Söke Sarıkemer 62: Buğday\n' +
                    'Aydın Söke Sarıkemer 31: Pamuk\n' +
                    'Aydın Söke Sarıkemer 32: Pamuk\n' +
                    'Aydın Söke Sarıkemer 33: Pamuk\n' +
                    'Aydın Söke Sarıkemer 34: Buğday\n' +
                    'Aydın Söke Sarıkemer 35: Pamuk\n' +
                    'Aydın Söke Sarıkemer 67: Buğday\n' +
                    'Aydın Söke Sarıkemer 73: Buğday\n' +
                    'Aydın Söke Sarıkemer 78: Buğday\n' +
                    'Aydın Söke Sarıkemer 37: Pamuk\n' +
                    'Aydın Söke Sarıkemer 38: Pamuk\n' +
                    'Aydın Söke Sarıkemer 39: Pamuk\n' +
                    'Aydın Söke Sarıkemer 40: Buğday\n' +
                    'Aydın Söke Sarıkemer 42: Buğday\n' +
                    'Aydın Söke Sarıkemer 43: Buğday\n' +
                    'Aydın Söke Sarıkemer 44: Buğday\n' +
                    'Aydın Söke Sarıkemer 45: Buğday\n' +
                    'Aydın Söke Sarıkemer 46: Buğday\n' +
                    'Aydın Söke Sarıkemer 47: Buğday\n' +
                    'Aydın Söke Sarıkemer 48: Buğday\n' +
                    'Aydın Söke Sarıkemer 49: Buğday\n' +
                    'Aydın Söke Sarıkemer 50: Buğday\n' +
                    'Aydın Söke Sarıkemer 51: Pamuk\n' +
                    'Aydın Söke Sarıkemer 83: Pamuk\n' +
                    'Aydın Söke Sarıkemer 98: Buğday\n' +
                    'Aydın Söke Sarıkemer 52: Pamuk\n' +
                    'Aydın Söke Sarıkemer 53: Yonca\n' +
                    'Aydın Söke Sarıkemer 54: Yonca\n' +
                    'Aydın Söke Sarıkemer 55: Yonca\n' +
                    'Aydın Söke Sarıkemer 56: Buğday\n' +
                    'Aydın Söke Sarıkemer 57: Buğday\n' +
                    'Aydın Söke Sarıkemer 58: Buğday\n' +
                    'Aydın Söke Sarıkemer 59: Buğday\n' +
                    'Aydın Söke Sarıkemer 60: Buğday\n' +
                    'Aydın Söke Sarıkemer 61: Buğday\n' +
                    'Aydın Söke Sarıkemer 173: Pamuk\n' +
                    'Aydın Söke Sarıkemer 178: Pamuk\n' +
                    'Aydın Söke Sarıkemer 63: Pamuk\n' +
                    'Aydın Söke Sarıkemer 64: Buğday\n' +
                    'Aydın Söke Sarıkemer 65: Diğer\n' +
                    'Aydın Söke Sarıkemer 66: Buğday\n' +
                    'Aydın Söke Sarıkemer 68: Buğday\n' +
                    'Aydın Söke Sarıkemer 69: Buğday\n' +
                    'Aydın Söke Sarıkemer 70: Buğday\n' +
                    'Aydın Söke Sarıkemer 71: Buğday\n' +
                    'Aydın Söke Sarıkemer 72: Buğday\n' +
                    'Aydın Söke Sarıkemer 74: Diğer\n' +
                    'Aydın Söke Sarıkemer 75: Diğer\n' +
                    'Aydın Söke Sarıkemer 76: Buğday\n' +
                    'Aydın Söke Sarıkemer 77: Buğday\n' +
                    'Aydın Söke Sarıkemer 79: Pamuk\n' +
                    'Aydın Söke Sarıkemer 80: Buğday\n' +
                    'Aydın Söke Sarıkemer 81: Pamuk\n' +
                    'Aydın Söke Sarıkemer 82: Buğday\n' +
                    'Aydın Söke Sarıkemer 84: Diğer\n' +
                    'Aydın Söke Sarıkemer 85: Buğday\n' +
                    'Aydın Söke Sarıkemer 86: Buğday\n' +
                    'Aydın Söke Sarıkemer 87: Buğday\n' +
                    'Aydın Söke Sarıkemer 88: Buğday\n' +
                    'Aydın Söke Sarıkemer 89: Buğday\n' +
                    'Aydın Söke Sarıkemer 90: Buğday\n' +
                    'Aydın Söke Sarıkemer 91: Buğday\n' +
                    'Aydın Söke Sarıkemer 92: Buğday\n' +
                    'Aydın Söke Sarıkemer 198: Pamuk\n' +
                    'Aydın Söke Sarıkemer 208: Buğday\n' +
                    'Aydın Söke Sarıkemer 93: Buğday\n' +
                    'Aydın Söke Sarıkemer 94: Diğer\n' +
                    'Aydın Söke Sarıkemer 95: Pamuk\n' +
                    'Aydın Söke Sarıkemer 96: Buğday\n' +
                    'Aydın Söke Sarıkemer 97: Buğday\n' +
                    'Aydın Söke Sarıkemer 213: Buğday\n' +
                    'Afyon Sandıklı Alamescit 237: Buğday\n' +
                    'Afyon Sandıklı Alamescit 243: Haşhaş\n' +
                    'Aydın Söke Sarıkemer 99: Buğday\n' +
                    'Aydın Söke Sarıkemer 101: Pamuk\n' +
                    'Aydın Söke Sarıkemer 102: Buğday\n' +
                    'Aydın Söke Sarıkemer 103: Buğday\n' +
                    'Aydın Söke Sarıkemer 104: Diğer\n' +
                    'Aydın Söke Sarıkemer 105: Buğday\n' +
                    'Aydın Söke Sarıkemer 106: Pamuk\n' +
                    'Aydın Söke Sarıkemer 107: Buğday\n' +
                    'Afyon Sandıklı Alamescit 334: Diğer\n' +
                    'Afyon Sandıklı Alamescit 340: Diğer\n' +
                    'Aydın Söke Sarıkemer 108: Buğday\n' +
                    'Aydın Söke Sarıkemer 109: Buğday\n' +
                    'Aydın Söke Sarıkemer 110: Pamuk\n' +
                    'Aydın Söke Sarıkemer 111: Pamuk\n' +
                    'Aydın Söke Sarıkemer 112: Buğday\n' +
                    'Aydın Söke Sarıkemer 114: Buğday\n' +
                    'Aydın Söke Sarıkemer 115: Buğday\n' +
                    'Aydın Söke Sarıkemer 116: Buğday\n' +
                    'Aydın Söke Sarıkemer 117: Buğday\n' +
                    'Afyon Sandıklı Alamescit 351: Diğer\n' +
                    'Konya Karatay Divanlar 369: Nadas\n' +
                    'Konya Karatay Divanlar 375: Buğday\n' +
                    'Aydın Söke Sarıkemer 118: Buğday\n' +
                    'Aydın Söke Sarıkemer 119: Buğday\n' +
                    'Aydın Söke Sarıkemer 120: Pamuk\n' +
                    'Aydın Söke Sarıkemer 121: Diğer\n' +
                    'Aydın Söke Sarıkemer 122: Pamuk\n' +
                    'Aydın Söke Sarıkemer 124: Buğday\n' +
                    'Aydın Söke Sarıkemer 125: Diğer\n' +
                    'Aydın Söke Sarıkemer 126: Diğer\n' +
                    'Aydın Söke Sarıkemer 127: Buğday\n' +
                    'Aydın Söke Sarıkemer 129: Diğer\n' +
                    'Aydın Söke Sarıkemer 130: Diğer\n' +
                    'Aydın Söke Sarıkemer 131: Pamuk\n' +
                    'Aydın Söke Sarıkemer 132: Buğday\n' +
                    'Aydın Söke Sarıkemer 134: Diğer\n' +
                    'Aydın Söke Sarıkemer 135: Diğer\n' +
                    'Aydın Söke Sarıkemer 136: Buğday\n' +
                    'Aydın Söke Sarıkemer 137: Buğday\n' +
                    'Afyon Sandıklı Alamescit 219: Haşhaş\n' +
                    'Aydın Söke Sarıkemer 138: Diğer\n' +
                    'Aydın Söke Sarıkemer 139: Buğday\n' +
                    'Aydın Söke Sarıkemer 140: Diğer\n' +
                    'Aydın Söke Sarıkemer 141: Pamuk\n' +
                    'Aydın Söke Sarıkemer 142: Pamuk\n' +
                    'Konya Karatay Divanlar 381: Nadas\n' +
                    'Konya Karatay Divanlar 393: Nadas\n' +
                    'Konya Karatay Divanlar 399: Buğday\n' +
                    'Aydın Söke Sarıkemer 144: Diğer\n' +
                    'Aydın Söke Sarıkemer 145: Buğday\n' +
                    'Aydın Söke Sarıkemer 146: Pamuk\n' +
                    'Aydın Söke Sarıkemer 147: Buğday\n' +
                    'Aydın Söke Sarıkemer 148: Buğday\n' +
                    'Aydın Söke Sarıkemer 149: Diğer\n' +
                    'Aydın Söke Sarıkemer 150: Buğday\n' +
                    'Aydın Söke Sarıkemer 151: Buğday\n' +
                    'Aydın Söke Sarıkemer 152: Buğday\n' +
                    'Konya Karatay Divanlar 405: Nadas\n' +
                    'Konya Karatay Divanlar 411: Nadas\n' +
                    'Konya Karatay Divanlar 417: Nadas\n' +
                    'Konya Karatay Divanlar 423: Buğday\n' +
                    'Aydın Söke Sarıkemer 154: Buğday\n' +
                    'Aydın Söke Sarıkemer 155: Buğday\n' +
                    'Aydın Söke Sarıkemer 156: Buğday\n' +
                    'Aydın Söke Sarıkemer 157: Diğer\n' +
                    'Aydın Söke Sarıkemer 158: Diğer\n' +
                    'Aydın Söke Sarıkemer 159: Buğday\n' +
                    'Aydın Söke Sarıkemer 160: Buğday\n' +
                    'Aydın Söke Sarıkemer 161: Buğday\n' +
                    'Aydın Söke Sarıkemer 162: Pamuk\n' +
                    'Konya Karatay Divanlar 429: Nadas\n' +
                    'Konya Karatay Divanlar 435: Buğday\n' +
                    'Aydın Söke Sarıkemer 163: Buğday\n' +
                    'Aydın Söke Sarıkemer 164: Buğday\n' +
                    'Aydın Söke Sarıkemer 165: Buğday\n' +
                    'Aydın Söke Sarıkemer 166: Buğday\n' +
                    'Aydın Söke Sarıkemer 167: Buğday\n' +
                    'Aydın Söke Sarıkemer 169: Pamuk\n' +
                    'Aydın Söke Sarıkemer 170: Buğday\n' +
                    'Aydın Söke Sarıkemer 171: Buğday\n' +
                    'Aydın Söke Sarıkemer 172: Buğday\n' +
                    'Konya Karatay Divanlar 446: Buğday\n' +
                    'Konya Karatay Divanlar 452: Silajlık Mısır\n' +
                    'Konya Karatay Divanlar 464: Buğday\n' +
                    'Konya Karatay Divanlar 476: Buğday\n' +
                    'Aydın Söke Sarıkemer 174: Pamuk\n' +
                    'Aydın Söke Sarıkemer 175: Pamuk\n' +
                    'Aydın Söke Sarıkemer 176: Pamuk\n' +
                    'Aydın Söke Sarıkemer 177: Pamuk\n' +
                    'Konya Karatay Divanlar 482: Nadas\n' +
                    'Konya Karatay Divanlar 488: Buğday\n' +
                    'Konya Karatay Divanlar 494: Buğday\n' +
                    'Konya Karatay Divanlar 500: Buğday\n' +
                    'Konya Karatay Divanlar 506: Buğday\n' +
                    'Konya Karatay Divanlar 512: Buğday\n' +
                    'Aydın Söke Sarıkemer 179: Pamuk\n' +
                    'Aydın Söke Sarıkemer 180: Buğday\n' +
                    'Aydın Söke Sarıkemer 181: Pamuk\n' +
                    'Aydın Söke Sarıkemer 182: Buğday\n' +
                    'Aydın Söke Sarıkemer 183: Buğday\n' +
                    'Aydın Söke Sarıkemer 184: Pamuk\n' +
                    'Aydın Söke Sarıkemer 185: Pamuk\n' +
                    'Aydın Söke Sarıkemer 186: Buğday\n' +
                    'Aydın Söke Sarıkemer 187: Buğday\n' +
                    'Konya Karatay Divanlar 524: Buğday\n' +
                    'Konya Karatay Divanlar 530: Buğday\n' +
                    'Aydın Söke Sarıkemer 188: Buğday\n' +
                    'Aydın Söke Sarıkemer 189: Buğday\n' +
                    'Aydın Söke Sarıkemer 190: Buğday\n' +
                    'Aydın Söke Sarıkemer 191: Buğday\n' +
                    'Aydın Söke Sarıkemer 192: Pamuk\n' +
                    'Konya Karatay Divanlar 536: Buğday\n' +
                    'Konya Karatay Divanlar 542: Buğday\n' +
                    'Konya Karatay Divanlar 548: Buğday\n' +
                    'Aydın Söke Sarıkemer 193: Buğday\n' +
                    'Aydın Söke Sarıkemer 194: Pamuk\n' +
                    'Aydın Söke Sarıkemer 195: Pamuk\n' +
                    'Aydın Söke Sarıkemer 196: Buğday\n' +
                    'Aydın Söke Sarıkemer 197: Buğday\n' +
                    'Konya Karatay Divanlar 566: Buğday\n' +
                    'Konya Karatay Divanlar 569: Buğday\n' +
                    'Konya Karatay Divanlar 572: Ayçiçeği\n' +
                    'Aydın Söke Sarıkemer 199: Diğer\n' +
                    'Aydın Söke Sarıkemer 200: Diğer\n' +
                    'Aydın Söke Sarıkemer 201: Pamuk\n' +
                    'Aydın Söke Sarıkemer 202: Pamuk\n' +
                    'Aydın Söke Sarıkemer 203: Pamuk\n' +
                    'Aydın Söke Sarıkemer 204: Pamuk\n' +
                    'Aydın Söke Sarıkemer 205: Pamuk\n' +
                    'Aydın Söke Sarıkemer 206: Pamuk\n' +
                    'Aydın Söke Sarıkemer 207: Pamuk\n' +
                    'Konya Karatay Divanlar 578: Buğday\n' +
                    'Konya Karatay Divanlar 584: Buğday\n' +
                    'Aydın Söke Sarıkemer 209: Buğday\n' +
                    'Aydın Söke Sarıkemer 210: Buğday\n' +
                    'Aydın Söke Sarıkemer 211: Buğday\n' +
                    'Aydın Söke Sarıkemer 212: Pamuk\n' +
                    'Konya Karatay Divanlar 585: Arpa\n' +
                    'Konya Karatay Divanlar 586: Arpa\n' +
                    'Konya Karatay Divanlar 587: Arpa\n' +
                    'Konya Karatay Divanlar 588: Buğday\n' +
                    'Konya Karatay Divanlar 589: Buğday\n' +
                    'Konya Karatay Divanlar 590: Nadas\n' +
                    'Aydın Söke Sarıkemer 214: Buğday\n' +
                    'Aydın Söke Sarıkemer 215: Pamuk\n' +
                    'Afyon Sandıklı Alamescit 216: Diğer\n' +
                    'Afyon Sandıklı Alamescit 217: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 218: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 220: Diğer\n' +
                    'Afyon Sandıklı Alamescit 221: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 222: Diğer\n' +
                    'Afyon Sandıklı Alamescit 223: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 224: Diğer\n' +
                    'Afyon Sandıklı Alamescit 225: Buğday\n' +
                    'Konya Karatay Divanlar 591: Nadas\n' +
                    'Konya Karatay Divanlar 592: Arpa\n' +
                    'Konya Karatay Divanlar 593: Arpa\n' +
                    'Afyon Sandıklı Alamescit 226: Diğer\n' +
                    'Afyon Sandıklı Alamescit 227: Buğday\n' +
                    'Afyon Sandıklı Alamescit 228: Arpa\n' +
                    'Afyon Sandıklı Alamescit 229: Buğday\n' +
                    'Afyon Sandıklı Alamescit 230: Arpa\n' +
                    'Afyon Sandıklı Alamescit 231: Diğer\n' +
                    'Afyon Sandıklı Alamescit 232: Diğer\n' +
                    'Afyon Sandıklı Alamescit 233: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 234: Şeker Pancarı\n' +
                    'Afyon Sandıklı Alamescit 235: Buğday\n' +
                    'Afyon Sandıklı Alamescit 236: Diğer\n' +
                    'Afyon Sandıklı Alamescit 238: Buğday\n' +
                    'Afyon Sandıklı Alamescit 239: Diğer\n' +
                    'Afyon Sandıklı Alamescit 240: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 241: Diğer\n' +
                    'Afyon Sandıklı Alamescit 242: Diğer\n' +
                    'Afyon Sandıklı Alamescit 244: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 245: Şeker Pancarı\n' +
                    'Afyon Sandıklı Alamescit 246: Şeker Pancarı\n' +
                    'Afyon Sandıklı Alamescit 247: Diğer\n' +
                    'Afyon Sandıklı Alamescit 248: Arpa\n' +
                    'Afyon Sandıklı Alamescit 249: Diğer\n' +
                    'Afyon Sandıklı Alamescit 250: Ayçiçeği\n' +
                    'Afyon Sandıklı Alamescit 251: Diğer\n' +
                    'Afyon Sandıklı Alamescit 252: Ayçiçeği\n' +
                    'Afyon Sandıklı Alamescit 253: Ayçiçeği\n' +
                    'Afyon Sandıklı Alamescit 254: Ayçiçeği\n' +
                    'Afyon Sandıklı Alamescit 255: Diğer\n' +
                    'Afyon Sandıklı Alamescit 256: Diğer\n' +
                    'Afyon Sandıklı Alamescit 257: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 258: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 259: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 260: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 261: Diğer\n' +
                    'Afyon Sandıklı Alamescit 262: Diğer\n' +
                    'Afyon Sandıklı Alamescit 263: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 264: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 265: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 266: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 267: Diğer\n' +
                    'Afyon Sandıklı Alamescit 268: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 269: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 270: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 271: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 272: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 273: Şeker Pancarı\n' +
                    'Afyon Sandıklı Alamescit 274: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 275: Diğer\n' +
                    'Afyon Sandıklı Alamescit 276: Şeker Pancarı\n' +
                    'Afyon Sandıklı Alamescit 277: Buğday\n' +
                    'Afyon Sandıklı Alamescit 278: Buğday\n' +
                    'Afyon Sandıklı Alamescit 279: Buğday\n' +
                    'Afyon Sandıklı Alamescit 280: Buğday\n' +
                    'Afyon Sandıklı Alamescit 281: Buğday\n' +
                    'Afyon Sandıklı Alamescit 282: Buğday\n' +
                    'Afyon Sandıklı Alamescit 283: Şeker Pancarı\n' +
                    'Afyon Sandıklı Alamescit 284: Buğday\n' +
                    'Afyon Sandıklı Alamescit 285: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 286: Diğer\n' +
                    'Afyon Sandıklı Alamescit 287: Patates\n' +
                    'Afyon Sandıklı Alamescit 288: Patates\n' +
                    'Afyon Sandıklı Alamescit 289: Patates\n' +
                    'Afyon Sandıklı Alamescit 290: Şeker Pancarı\n' +
                    'Afyon Sandıklı Alamescit 291: Patates\n' +
                    'Afyon Sandıklı Alamescit 292: Diğer\n' +
                    'Afyon Sandıklı Alamescit 293: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 294: Patates\n' +
                    'Afyon Sandıklı Alamescit 295: Patates\n' +
                    'Afyon Sandıklı Alamescit 296: Patates\n' +
                    'Afyon Sandıklı Alamescit 298: Patates\n' +
                    'Afyon Sandıklı Alamescit 299: Arpa\n' +
                    'Afyon Sandıklı Alamescit 300: Diğer\n' +
                    'Afyon Sandıklı Alamescit 301: Diğer\n' +
                    'Afyon Sandıklı Alamescit 302: Buğday\n' +
                    'Afyon Sandıklı Alamescit 303: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 304: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 305: Buğday\n' +
                    'Afyon Sandıklı Alamescit 306: Diğer\n' +
                    'Afyon Sandıklı Alamescit 307: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 308: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 309: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 310: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 311: Diğer\n' +
                    'Afyon Sandıklı Alamescit 312: Diğer\n' +
                    'Afyon Sandıklı Alamescit 313: Diğer\n' +
                    'Afyon Sandıklı Alamescit 314: Diğer\n' +
                    'Afyon Sandıklı Alamescit 315: Diğer\n' +
                    'Afyon Sandıklı Alamescit 316: Diğer\n' +
                    'Afyon Sandıklı Alamescit 317: Diğer\n' +
                    'Afyon Sandıklı Alamescit 318: Diğer\n' +
                    'Afyon Sandıklı Alamescit 319: Diğer\n' +
                    'Afyon Sandıklı Alamescit 320: Diğer\n' +
                    'Afyon Sandıklı Alamescit 321: Diğer\n' +
                    'Afyon Sandıklı Alamescit 322: Diğer\n' +
                    'Afyon Sandıklı Alamescit 323: Ayçiçeği\n' +
                    'Afyon Sandıklı Alamescit 324: Ayçiçeği\n' +
                    'Afyon Sandıklı Alamescit 325: Diğer\n' +
                    'Afyon Sandıklı Alamescit 326: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 327: Diğer\n' +
                    'Afyon Sandıklı Alamescit 328: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 329: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 330: Ayçiçeği\n' +
                    'Afyon Sandıklı Alamescit 331: Ayçiçeği\n' +
                    'Afyon Sandıklı Alamescit 332: Diğer\n' +
                    'Afyon Sandıklı Alamescit 333: Diğer\n' +
                    'Afyon Sandıklı Alamescit 335: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 336: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 337: Diğer\n' +
                    'Afyon Sandıklı Alamescit 338: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 339: Diğer\n' +
                    'Afyon Sandıklı Alamescit 341: Diğer\n' +
                    'Afyon Sandıklı Alamescit 342: Diğer\n' +
                    'Afyon Sandıklı Alamescit 343: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 344: Diğer\n' +
                    'Afyon Sandıklı Alamescit 345: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 346: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 347: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 348: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 349: Buğday\n' +
                    'Afyon Sandıklı Alamescit 350: Diğer\n' +
                    'Afyon Sandıklı Alamescit 352: Diğer\n' +
                    'Afyon Sandıklı Alamescit 353: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 354: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 355: Diğer\n' +
                    'Afyon Sandıklı Alamescit 356: Diğer\n' +
                    'Afyon Sandıklı Alamescit 357: Buğday\n' +
                    'Afyon Sandıklı Alamescit 358: Diğer\n' +
                    'Afyon Sandıklı Alamescit 359: Diğer\n' +
                    'Afyon Sandıklı Alamescit 360: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 361: Haşhaş\n' +
                    'Afyon Sandıklı Alamescit 362: Ayçiçeği\n' +
                    'Afyon Sandıklı Alamescit 363: Diğer\n' +
                    'Afyon Sandıklı Alamescit 364: Diğer\n' +
                    'Konya Karatay Divanlar 365: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 366: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 367: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 368: Arpa\n' +
                    'Konya Karatay Divanlar 370: Buğday\n' +
                    'Konya Karatay Divanlar 371: Nadas\n' +
                    'Konya Karatay Divanlar 372: Buğday\n' +
                    'Konya Karatay Divanlar 373: Arpa\n' +
                    'Konya Karatay Divanlar 374: Arpa\n' +
                    'Konya Karatay Divanlar 376: Arpa\n' +
                    'Konya Karatay Divanlar 377: Buğday\n' +
                    'Konya Karatay Divanlar 378: Buğday\n' +
                    'Konya Karatay Divanlar 379: Nadas\n' +
                    'Konya Karatay Divanlar 380: Nadas\n' +
                    'Konya Karatay Divanlar 382: Arpa\n' +
                    'Konya Karatay Divanlar 383: Nadas\n' +
                    'Konya Karatay Divanlar 384: Nadas\n' +
                    'Konya Karatay Divanlar 385: Buğday\n' +
                    'Konya Karatay Divanlar 386: Buğday\n' +
                    'Konya Karatay Divanlar 387: Buğday\n' +
                    'Konya Karatay Divanlar 388: Buğday\n' +
                    'Konya Karatay Divanlar 389: Buğday\n' +
                    'Konya Karatay Divanlar 390: Buğday\n' +
                    'Konya Karatay Divanlar 391: Nadas\n' +
                    'Konya Karatay Divanlar 392: Arpa\n' +
                    'Konya Karatay Divanlar 394: Nadas\n' +
                    'Konya Karatay Divanlar 395: Nadas\n' +
                    'Konya Karatay Divanlar 396: Buğday\n' +
                    'Konya Karatay Divanlar 397: Buğday\n' +
                    'Konya Karatay Divanlar 398: Arpa\n' +
                    'Konya Karatay Divanlar 400: Diğer\n' +
                    'Konya Karatay Divanlar 401: Nadas\n' +
                    'Konya Karatay Divanlar 402: Nadas\n' +
                    'Konya Karatay Divanlar 403: Arpa\n' +
                    'Konya Karatay Divanlar 404: Nadas\n' +
                    'Konya Karatay Divanlar 406: Nadas\n' +
                    'Konya Karatay Divanlar 407: Nadas\n' +
                    'Konya Karatay Divanlar 408: Nadas\n' +
                    'Konya Karatay Divanlar 409: Nadas\n' +
                    'Konya Karatay Divanlar 410: Nadas\n' +
                    'Konya Karatay Divanlar 412: Nadas\n' +
                    'Konya Karatay Divanlar 413: Nadas\n' +
                    'Konya Karatay Divanlar 414: Arpa\n' +
                    'Konya Karatay Divanlar 415: Nadas\n' +
                    'Konya Karatay Divanlar 416: Nadas\n' +
                    'Konya Karatay Divanlar 418: Buğday\n' +
                    'Konya Karatay Divanlar 419: Buğday\n' +
                    'Konya Karatay Divanlar 420: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 421: Buğday\n' +
                    'Konya Karatay Divanlar 422: Buğday\n' +
                    'Konya Karatay Divanlar 424: Buğday\n' +
                    'Konya Karatay Divanlar 425: Buğday\n' +
                    'Konya Karatay Divanlar 426: Buğday\n' +
                    'Konya Karatay Divanlar 427: Buğday\n' +
                    'Konya Karatay Divanlar 428: Buğday\n' +
                    'Konya Karatay Divanlar 430: Buğday\n' +
                    'Konya Karatay Divanlar 431: Buğday\n' +
                    'Konya Karatay Divanlar 432: Buğday\n' +
                    'Konya Karatay Divanlar 433: Buğday\n' +
                    'Konya Karatay Divanlar 434: Buğday\n' +
                    'Konya Karatay Divanlar 436: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 437: Buğday\n' +
                    'Konya Karatay Divanlar 438: Buğday\n' +
                    'Konya Karatay Divanlar 439: Nadas\n' +
                    'Konya Karatay Divanlar 440: Buğday\n' +
                    'Konya Karatay Divanlar 441: Buğday\n' +
                    'Konya Karatay Divanlar 442: Nadas\n' +
                    'Konya Karatay Divanlar 443: Buğday\n' +
                    'Konya Karatay Divanlar 444: Buğday\n' +
                    'Konya Karatay Divanlar 445: Buğday\n' +
                    'Konya Karatay Divanlar 447: Silajlık Mısır\n' +
                    'Konya Karatay Divanlar 448: Silajlık Mısır\n' +
                    'Konya Karatay Divanlar 449: Buğday\n' +
                    'Konya Karatay Divanlar 450: Buğday\n' +
                    'Konya Karatay Divanlar 451: Buğday\n' +
                    'Konya Karatay Divanlar 453: Silajlık Mısır\n' +
                    'Konya Karatay Divanlar 454: Silajlık Mısır\n' +
                    'Konya Karatay Divanlar 455: Buğday\n' +
                    'Konya Karatay Divanlar 456: Buğday\n' +
                    'Konya Karatay Divanlar 457: Silajlık Mısır\n' +
                    'Konya Karatay Divanlar 458: Buğday\n' +
                    'Konya Karatay Divanlar 459: Buğday\n' +
                    'Konya Karatay Divanlar 460: Buğday\n' +
                    'Konya Karatay Divanlar 461: Buğday\n' +
                    'Konya Karatay Divanlar 462: Buğday\n' +
                    'Konya Karatay Divanlar 463: Buğday\n' +
                    'Konya Karatay Divanlar 465: Buğday\n' +
                    'Konya Karatay Divanlar 466: Buğday\n' +
                    'Konya Karatay Divanlar 467: Yonca\n' +
                    'Konya Karatay Divanlar 468: Buğday\n' +
                    'Konya Karatay Divanlar 469: Buğday\n' +
                    'Konya Karatay Divanlar 470: Nadas\n' +
                    'Konya Karatay Divanlar 471: Buğday\n' +
                    'Konya Karatay Divanlar 472: Buğday\n' +
                    'Konya Karatay Divanlar 473: Buğday\n' +
                    'Konya Karatay Divanlar 474: Buğday\n' +
                    'Konya Karatay Divanlar 475: Buğday\n' +
                    'Konya Karatay Divanlar 477: Buğday\n' +
                    'Konya Karatay Divanlar 478: Buğday\n' +
                    'Konya Karatay Divanlar 479: Buğday\n' +
                    'Konya Karatay Divanlar 480: Buğday\n' +
                    'Konya Karatay Divanlar 481: Buğday\n' +
                    'Konya Karatay Divanlar 483: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 484: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 485: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 486: Nadas\n' +
                    'Konya Karatay Divanlar 487: Buğday\n' +
                    'Konya Karatay Divanlar 489: Buğday\n' +
                    'Konya Karatay Divanlar 490: Buğday\n' +
                    'Konya Karatay Divanlar 491: Nadas\n' +
                    'Konya Karatay Divanlar 492: Nadas\n' +
                    'Konya Karatay Divanlar 493: Buğday\n' +
                    'Konya Karatay Divanlar 495: Buğday\n' +
                    'Konya Karatay Divanlar 496: Buğday\n' +
                    'Konya Karatay Divanlar 497: Buğday\n' +
                    'Konya Karatay Divanlar 498: Buğday\n' +
                    'Konya Karatay Divanlar 499: Buğday\n' +
                    'Konya Karatay Divanlar 501: Buğday\n' +
                    'Konya Karatay Divanlar 502: Buğday\n' +
                    'Konya Karatay Divanlar 503: Buğday\n' +
                    'Konya Karatay Divanlar 504: Buğday\n' +
                    'Konya Karatay Divanlar 505: Buğday\n' +
                    'Konya Karatay Divanlar 507: Buğday\n' +
                    'Konya Karatay Divanlar 508: Silajlık Mısır\n' +
                    'Konya Karatay Divanlar 509: Silajlık Mısır\n' +
                    'Konya Karatay Divanlar 510: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 511: Buğday\n' +
                    'Konya Karatay Divanlar 513: Buğday\n' +
                    'Konya Karatay Divanlar 514: Silajlık Mısır\n' +
                    'Konya Karatay Divanlar 515: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 516: Nadas\n' +
                    'Konya Karatay Divanlar 517: Nadas\n' +
                    'Konya Karatay Divanlar 518: Nadas\n' +
                    'Konya Karatay Divanlar 519: Buğday\n' +
                    'Konya Karatay Divanlar 520: Nadas\n' +
                    'Konya Karatay Divanlar 521: Nadas\n' +
                    'Konya Karatay Divanlar 522: Buğday\n' +
                    'Konya Karatay Divanlar 523: Buğday\n' +
                    'Konya Karatay Divanlar 525: Buğday\n' +
                    'Konya Karatay Divanlar 526: Şeker Pancarı\n' +
                    'Konya Karatay Divanlar 527: Buğday\n' +
                    'Konya Karatay Divanlar 528: Buğday\n' +
                    'Konya Karatay Divanlar 529: Buğday\n' +
                    'Konya Karatay Divanlar 531: Buğday\n' +
                    'Konya Karatay Divanlar 532: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 533: Silajlık Mısır\n' +
                    'Konya Karatay Divanlar 534: Buğday\n' +
                    'Konya Karatay Divanlar 535: Buğday\n' +
                    'Konya Karatay Divanlar 537: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 538: Silajlık Mısır\n' +
                    'Konya Karatay Divanlar 539: Silajlık Mısır\n' +
                    'Konya Karatay Divanlar 540: Buğday\n' +
                    'Konya Karatay Divanlar 541: Buğday\n' +
                    'Konya Karatay Divanlar 543: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 544: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 545: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 546: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 547: Buğday\n' +
                    'Konya Karatay Divanlar 549: Buğday\n' +
                    'Konya Karatay Divanlar 550: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 551: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 552: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 553: Buğday\n' +
                    'Konya Karatay Divanlar 554: Buğday\n' +
                    'Konya Karatay Divanlar 555: Arpa\n' +
                    'Konya Karatay Divanlar 556: Arpa\n' +
                    'Konya Karatay Divanlar 557: Buğday\n' +
                    'Konya Karatay Divanlar 558: Buğday\n' +
                    'Konya Karatay Divanlar 559: Buğday\n' +
                    'Konya Karatay Divanlar 560: Buğday\n' +
                    'Konya Karatay Divanlar 561: Buğday\n' +
                    'Konya Karatay Divanlar 562: Buğday\n' +
                    'Konya Karatay Divanlar 563: Nadas\n' +
                    'Konya Karatay Divanlar 564: Buğday\n' +
                    'Konya Karatay Divanlar 565: Buğday\n' +
                    'Konya Karatay Divanlar 567: Buğday\n' +
                    'Konya Karatay Divanlar 568: Buğday\n' +
                    'Konya Karatay Divanlar 570: Buğday\n' +
                    'Konya Karatay Divanlar 571: Buğday\n' +
                    'Konya Karatay Divanlar 573: Ayçiçeği\n' +
                    'Konya Karatay Divanlar 574: Buğday\n' +
                    'Konya Karatay Divanlar 575: Silajlık Mısır\n' +
                    'Konya Karatay Divanlar 576: Şeker Pancarı\n' +
                    'Konya Karatay Divanlar 577: Buğday\n' +
                    'Konya Karatay Divanlar 579: Buğday\n' +
                    'Konya Karatay Divanlar 580: Buğday\n' +
                    'Konya Karatay Divanlar 581: Buğday\n' +
                    'Konya Karatay Divanlar 582: Buğday\n' +
                    'Konya Karatay Divanlar 583: Buğday\n' +
                    'Aydın Söke Sarıkemer 10: Buğday\n' +
                    'Aydın Söke Sarıkemer 15: Pamuk\n' +
                    'Aydın Söke Sarıkemer 20: Pamuk\n' +
                    'Aydın Söke Sarıkemer 36: Pamuk\n' +
                    'Aydın Söke Sarıkemer 100: Buğday\n' +
                    'Aydın Söke Sarıkemer 113: Buğday\n' +
                    'Aydın Söke Sarıkemer 123: Buğday\n' +
                    'Aydın Söke Sarıkemer 128: Diğer\n' +
                    'Aydın Söke Sarıkemer 133: Diğer\n' +
                    'Aydın Söke Sarıkemer 143: Buğday\n' +
                    'Aydın Söke Sarıkemer 153: Pamuk\n' +
                    'Aydın Söke Sarıkemer 168: Pamuk', function() {});
            }
        });

        return(
            <div id="widget" className="full-height col-12">
                <input type="hidden" id="hiddenInput" value={this.props.layerContentObj}/>

                <div className={"firstTable"}>
                    <table className="table table-hover table-dark">
                        <thead>
                        <tr>
                            <th colSpan="2">
                                <span className={"tableTitle"}>{this.state.mahalle_adi} Mahallesi {this.state.filter} Beyan Dağılımı:</span>
                            </th>
                        </tr>
                        <tr className={"Chart"}>
                            <td>
                                <PieChart data={chartData} />
                            </td>
                        </tr>
                        </thead>
                    </table>
                </div>

                <div>
                    <table className="table table-hover table-dark">
                        <thead>
                        <tr>
                            <th colSpan="2">
                                <span className={"tableTitle"}>Beyan Edilen Ürün:</span>
                                <button type="button" id={"saveButton"} className="btn btn-outline-success btn-sm btnSave btnDisable" onClick={this.saveStatementData.bind(this)}>Kaydet</button>
                            </th>
                        </tr>
                        <tr className={"statementSelections"}>
                            <td>
                                <div className="radio">
                                    <label onChange={this.handleChange.bind(this)}
                                           className={"table-label containerWidget"}>
                                        <input type="radio" id='radio1' name="radio" className={"table-radio"}
                                               value={"Ayçiçeği"} disabled={this.state.btn_disabled}/>
                                        <span className="checkmark"></span>Ayçiçeği</label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label onChange={this.handleChange.bind(this)}
                                           className={"table-label containerWidget"}>
                                        <input type="radio" id='radio2' name="radio" className={"table-radio"}
                                               value={"Arpa"} disabled={this.state.btn_disabled}/>
                                        <span className="checkmark"></span>Arpa</label>
                                </div>
                            </td>
                        </tr>
                        <tr className={"statementSelections"}>
                            <td>
                                <div className="radio">
                                    <label onChange={this.handleChange.bind(this)}
                                           className={"table-label containerWidget"}><input type="radio"
                                                                                            id='radio3'
                                                                                            name="radio"
                                                                                            className={"table-radio"}
                                                                                            value={"Buğday"}
                                                                                            disabled={this.state.btn_disabled}/><span
                                        className="checkmark"></span>Buğday</label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label onChange={this.handleChange.bind(this)}
                                           className={"table-label containerWidget"}><input type="radio"
                                                                                            id='radio4'
                                                                                            name="radio"
                                                                                            className={"table-radio"}
                                                                                            value={"Haşhaş"}
                                                                                            disabled={this.state.btn_disabled}/><span
                                        className="checkmark"></span>Haşhaş</label>
                                </div>
                            </td>
                        </tr>
                        <tr className={"statementSelections"}>
                            <td>
                                <div className="radio">
                                    <label onChange={this.handleChange.bind(this)}
                                           className={"table-label containerWidget"}><input type="radio"
                                                                                            id='radio5'
                                                                                            name="radio"
                                                                                            className={"table-radio"}
                                                                                            value={"Mısır"}
                                                                                            disabled={this.state.btn_disabled}/><span
                                        className="checkmark"></span>Mısır</label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label onChange={this.handleChange.bind(this)}
                                           className={"table-label containerWidget"}><input type="radio"
                                                                                            id='radio6'
                                                                                            name="radio"
                                                                                            className={"table-radio"}
                                                                                            value={"Şeker Pancarı"}
                                                                                            disabled={this.state.btn_disabled}/><span
                                        className="checkmark"></span>Şeker Pancarı</label>
                                </div>
                            </td>
                        </tr>
                        <tr className={"statementSelections"}>
                            <td>
                                <div className="radio">
                                    <label onChange={this.handleChange.bind(this)}
                                           className={"table-label containerWidget"}><input type="radio"
                                                                                            id='radio7'
                                                                                            name="radio"
                                                                                            className={"table-radio"}
                                                                                            value={"Pamuk"}
                                                                                            disabled={this.state.btn_disabled}/><span
                                        className="checkmark"></span>Pamuk</label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label onChange={this.handleChange.bind(this)}
                                           className={"table-label containerWidget"}><input type="radio"
                                                                                            id='radio8'
                                                                                            name="radio"
                                                                                            className={"table-radio"}
                                                                                            value={"Patates"}
                                                                                            disabled={this.state.btn_disabled}/><span
                                        className="checkmark"></span>Patates</label>
                                </div>
                            </td>
                        </tr>
                        <tr className={"statementSelections"}>
                            <td>
                                <div className="radio">
                                    <label onChange={this.handleChange.bind(this)}
                                           className={"table-label containerWidget"}><input type="radio"
                                                                                            id='radio9'
                                                                                            name="radio"
                                                                                            className={"table-radio"}
                                                                                            value={"Yonca"}
                                                                                            disabled={this.state.btn_disabled}/><span
                                        className="checkmark"></span>Yonca</label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label onChange={this.handleChange.bind(this)}
                                           className={"table-label containerWidget"}><input type="radio"
                                                                                            id='radio10'
                                                                                            name="radio"
                                                                                            className={"table-radio"}
                                                                                            value={"Diğer"}
                                                                                            disabled={this.state.btn_disabled}/><span
                                        className="checkmark"></span>Diğer</label>
                                </div>
                            </td>
                        </tr>
                        </thead>
                    </table>
                </div>


            </div>
        )
    }
}

export default Widget;