import React, { Component } from 'react';
import './App.css'
import {Map} from './Map'
import {Widget} from './Widget'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Login} from './Login';

import Sidebar from "react-sidebar";

var fs = require('browserify-fs');
var datas = [];
const styles = {
    contentHeaderMenuLink: {
        position: "absolute",
        marginTop: "125px",
        marginLeft: "12px",
        zIndex: 1,
        paddingBottom: "5px",
        paddingLeft: "8px",
        paddingTop: "5px",
        paddingRight: "8px",
        color: "#000",
        borderRadius: "1.5px",
        border: "1px solid #rgb(255, 255, 255)",
        boxShadow: "rgba(0, 0, 0, 0.20) 0px 0px 0px 2px",
        backgroundColor: "#fff",
        textDecoration: "none",
        display: "none"
    }
};

const mql = window.matchMedia('(min-width: 1200px)');

class App extends Component {
    constructor(props){
        super(props);
        this.state = {points: [], layerContent : "",docked: mql.matches,
            open: false}
        this.setPointsMain = this.setPointsMain.bind(this);
        this.getLayerContent = this.getLayerContent.bind(this);
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.onSetOpen = this.onSetOpen.bind(this);
    }

    static isLogged() {
        let loginData = localStorage.getItem('login');
        if (loginData!==null && loginData==="true"){
            return true;
        }
        return false;
    }

    componentWillMount() {
        mql.addListener(this.mediaQueryChanged);
    }

    componentWillUnmount() {
        mql.removeListener(this.mediaQueryChanged);
    }

    onSetOpen(open) {
        this.setState({ open });
    }

    mediaQueryChanged() {
        this.setState({
            docked: mql.matches,
            open: false,
            left: "0px"
        });
    }

    toggleOpen(ev) {
        this.setState({ open: !this.state.open });

        if (ev) {
            ev.preventDefault();
        }
    }

    setPointsMain(point1, point2, dblClckValue){
        /*if (String(dblClckValue) === "0")
        {
            let newPoints = this.state.points;
            newPoints.push([point1, point2]);
            this.setState({points: newPoints});
            L.circle([point1, point2], {radius: 10}).addTo(App.map);
            var polyline = L.polyline(this.state.points, {color: 'red'}).addTo(App.map);
        }
        else
        {
            var polygon = L.polygon(this.state.points, {color: 'red'}).addTo(App.map);
        }*/
    }

    getLayerContent(layerContent)
    {
        this.setState({layerContent: layerContent});
    }
    render() {
        //localStorage.clear();
        var map;
        var layerMap;
        var elementArray;
        var layerArray;
        const sidebar = <Widget layerContentObj ={this.state.layerContent} sendLayerContent = {this.getLayerContent}/>;
        const sidebarProps = {
            sidebar,
            docked: this.state.docked,
            open: this.state.open,
            onSetOpen: this.onSetOpen,
            sidebarClassName:"col-3",
            contentClassName:"col-9",
            className:"mainContainer"
        };

        let layout = (<Login doLogin={this.doLogin} />);
        if (App.isLogged()===true) {
            layout = ("");
        }
        else
        {
            layout = (<div><Login doLogin={this.doLogin} /><div className="parentDisable">
            </div></div>);
        }
        return (
            <div className="full-height row">
                {layout}
                <Sidebar {...sidebarProps}>
                    <a
                        className="fa fa-bars sideMenuButton"
                        onClick={this.toggleOpen}
                        href="#"
                        style={styles.contentHeaderMenuLink}
                    >
                    </a>
                    <Map sendLayerContent = {this.getLayerContent}/>
                </Sidebar>
            </div>

        );
    }
}

export default App;
